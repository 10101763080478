import MainNav from "../Navigation/mainnav";
import T12Table from "../Bodies/t12_table";
import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';
import GetProperties from "../Drive/get_properties";
import Typography from '@material-ui/core/Typography';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SaveT12 from "../Drive/save_t12";
import CropPortraitIcon from '@mui/icons-material/CropPortrait';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import {ToggleButton, ToggleButtonGroup } from "@mui/material";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDispatch, useSelector } from "react-redux";
import { save } from '../Redux/property_slice';
import {Alert} from '@mui/material';

const useStyles = makeStyles((theme) => ({
    dropdownContainer: {
        width: '20vw',
        // paddingRight: '2vw',
        // marginLeft: '2vw',
    },
    dropdown: {
        // paddingRight: '2vw',
    },
    datepicker: {
        minWidth: '200px',
        width: '300px',
    },
    datePickerContainer: {
        '@media (max-width: 780px)': {
            marginTop: '10px',
          }
    },
    button: {
        "&:hover": {
            backgroundColor: "white",
        }
    },
    header: {
        display: 'flex',
        '@media (max-width: 780px)': {
            display: 'block',
          }
    }

}));

const downloadFile = (orientation, format) => {
    let ori = orientation == "portrait";
    window.open(`https://docs.google.com/spreadsheets/d/1hlEUij_M_g1EybvlNAWRLTrW1fDKFbxjNY3VQdvgI_w/export?gid=0&format=${format}&portrait=${ori}`, "_system");

  };

const getPreviousMonth = () => {
    let curr = new Date();
    curr.setTime(curr.getTime()-curr.getTimezoneOffset()*60*1000);
    var d = dayjs(curr);
    d = d.month(d.month() - 1);
    return d;
}


export default function Dashboard(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [properties, setProperties] = React.useState([]);
    const [property, setProperty] = React.useState(useSelector(state => state.property.value));
    const [monthyear, setMonthYear] = React.useState(getPreviousMonth());
    const [orientation, setOrientation] = React.useState('landscape');
    const [fileType, setFileType] = React.useState('pdf');
    const [downloading, setDownloading] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [loadingError, setLoadingError] = React.useState(false);
    const [error, setError] = React.useState(0);


    React.useEffect(() => {
        if (window.gapi.auth2 == undefined || !window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
            navigate('/signin')
        }
        else {
            GetProperties(setPropertiesAndDefault);
        }
    }, [])

    const setPropertiesAndDefault = (values) => {
        setProperties(values);
    }

    const handlePropertyChange = (event, value) => {
        setLoadingError(true);
        setProperty(value);
        dispatch(save(value));
    };

    const handleMonthYearChange = (value) => {
        setMonthYear(value);
    }

    const download = () => {
        setDownloading(true);
        SaveT12(property, monthyear.format('MM'), monthyear.format('YYYY'), handleDone);
    }

    const handleDone = (result) => {
        if (result.succeeded) {
            downloadFile(orientation, fileType);
        }
        setDownloading(false);
    }

    const handleOrientation = (event, newOrientation) => {
        setOrientation(newOrientation);
    };

    const handleFileType = (event, newFileType) => {
        setFileType(newFileType);
    }

    const refresh = () => {
        setCount(count + 1);
    }

    const updateCategoryError = (value) => {
        setLoadingError(false);
        setError(value);
    }

    return (
        <>
            <MainNav/>
            <Divider sx={{ borderBottomWidth: 3}}/>
                <Typography variant="h5" style={{paddingLeft: '4vw', paddingTop: '1vw'}}>Trailing 12 Months Dashboard</Typography>

            <div style={{paddingLeft: '4vw', paddingRight: '4vw', paddingTop: '1vw'}}>
                <Box component="div" className={classes.header}>
                    <div style={{ paddingRight: '2vw'}}>
                    <Autocomplete
                        disablePortal
                        value = {property}
                        id="combo-box-demo"
                        options={properties}
                        sx={{ width: '300px' }}
                        disableClearable
                        onChange={handlePropertyChange}
                        renderInput={(params) => <TextField {...params} label="Property" InputLabelProps={{ shrink: true }}/>}
                        />  
                    </div>
                    <div className={classes.datePickerContainer}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker 
                                    label="Ending Month" 
                                    views={['month', 'year']} 
                                    value={monthyear}
                                    className={classes.datepicker}
                                    onChange={handleMonthYearChange}
                                    />
                        </LocalizationProvider>
                    </div>
                    <IconButton 
                    size="large" 
                    style={{borderRadius: 10,}}
                    sx={{
                        '@media (max-width: 780px)': {
                            display: 'none',
                          },
                        "&:hover": {backgroundColor: "transparent" }
                    }}
                    onClick={refresh}
                    >
                        <Tooltip title={"Refresh"}>
                            <RefreshIcon sx={{fontSize: "40px", color: '#808080', "&:hover": {color: "black" }}} />
                        </Tooltip>
                    </IconButton>
                    {property != "" && (
                    <Box 
                    style={{paddingTop: '5px'}}
                    >
                        <ToggleButtonGroup
                        value={fileType}
                        exclusive
                        onChange={handleFileType}
                        // aria-label="text alignment"
                        style={{height: '50px'}}
                        >
                            <ToggleButton value="pdf" aria-label="centered">
                                <Tooltip title={"select pdf format"}>
                                    <Typography>PDF</Typography>
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton value="csv" aria-label="left aligned">
                                <Tooltip title={"select csv format"}>
                                    <Typography>CSV</Typography>
                                </Tooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <ToggleButtonGroup
                        value={orientation}
                        exclusive
                        onChange={handleOrientation}
                        // aria-label="text alignment"
                        style={{paddingLeft: '2vw', height: '50px', verticalAlign: 'middle'}}
                        >
                            <ToggleButton value="landscape" aria-label="centered">
                                <Tooltip title={"Landscape"}>
                                    <CropLandscapeIcon />
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton value="portrait" aria-label="left aligned">
                                <Tooltip title={"Portrait"}>
                                    <CropPortraitIcon />
                                </Tooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {downloading ? (<div 
                        style={{display: 'inline'}}
                        ><IconButton><CircularProgress /></IconButton></div>):(
                    <IconButton 
                    size="large" 
                    style={{borderRadius: 10,}}
                    sx={{"&:hover": {backgroundColor: "transparent" }}}
                    onClick={download}
                    >
                        <Tooltip title={"Download"}>
                            <SaveAltIcon sx={{fontSize: "40px", color: '#808080', "&:hover": {color: "black" }}} />
                        </Tooltip>
                        </IconButton>
                    )}
                    { loadingError ? (<IconButton sx={{padding: 0}}><CircularProgress color="error"/></IconButton>):(error !== 0 && (<IconButton sx={{padding: 0}} onClick={() => navigate('/audit')}>
                        <Tooltip title={`There are invalid categories`}>
                        <Alert severity="error" >{error}</Alert>
                        </Tooltip>
                    </IconButton>))}
                    </Box>
                    )}
                </Box>
                <Divider style={{paddingTop: '1vh'}}/>
                {property != "" && (<T12Table property={property} count={count} month={monthyear.format('MM')} year={monthyear.format('YYYY')} monthyear={monthyear.format('MM/YYYY')} updateCategoryError={updateCategoryError}/>)}
            </div>
        </>
    )
}
